:root {
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}
a {
  text-decoration: none;
}
